import { makeStyles, createStyles, Theme } from '@material-ui/core';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100vw',
      padding: '0 20px 100px',
      minWidth: 360,
      position: 'relative',

      '& strong': {
        fontSize: 20,
        fontWeight: 700,
      },

      '& button': {
        textTransform: 'inherit',
        fontSize: 16,
      },

      '& #backButton': {
        position: 'absolute',
        left: 0,
      },

      '& #closeButton': {
        position: 'absolute',
        right: 0,
      },
    },
  })
);

export default styles;
