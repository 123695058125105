import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FormFields } from '../FormContainer';
import FormHeader from '../Header';
import processResult from '../utils/processResult';
import processScore from '../utils/processScore';

import styles from './styles';

interface Props {
  values: FormFields;
  showScore?: boolean;
}

export default function Result({ values, showScore = false }: Props) {
  const classes = styles();

  const [score, setScore] = useState({
    noSymptoms: 0,
    thyroid: 0,
    endometriosis: 0,
    pcos: 0,
  });

  useEffect(() => {
    const temporaryScore = processScore(values);
    setScore(temporaryScore);
  }, [values]);

  function renderResult() {
    const finalResult = processResult(score, values);

    switch (finalResult) {
      case 'endometriosis':
        return <Endometriosis />;
      case 'pcos':
        return <PCOS />;
      case 'thyroid':
        return <Thyroid />;
      default:
        return <NoSymptoms />;
    }
  }

  function renderScore() {
    return (
      <>
        <pre>{JSON.stringify(score)}</pre>
      </>
    );
  }

  function handleClose() {
    window.location.href = 'https://www.selfishdarling.com/';
  }

  if (showScore) {
    return renderScore();
  }

  return (
    <>
      <div style={{ padding: '30px 20px 0' }}>
        <FormHeader onClose={handleClose} />
      </div>
      <div className={classes.container}>
        <Typography variant='h3' align='center'>
          Here's your result darling
        </Typography>
        {renderResult()}
      </div>
    </>
  );
}

function NoSymptoms() {
  return (
    <>
      <Typography component='p'>
        Your quiz result didn’t raise any warning signs, however we always
        recommend you to have a general hormonal wellness check as this might be
        able to pick up something the quiz didn’t.
      </Typography>
      <Typography component='p'>
        The Female Hormonal Wellness Test is a great way to get an overall idea
        of your hormone levels and acts as a starting point for you to keep
        track of them. Everything might be fine today (and hopefully forever!)
        but by doing regular hormonal wellness checks you are able to quickly
        detect any unusual results and seek our experts advice immediately. It’s
        also a good idea to be aware of and keep an eye on your hormone levels
        if and when you are starting to think about pregnancy.
      </Typography>
      <Typography component='p'>
        The Female Hormonal Wellness test checks for hormone imbalances and if
        done regularly, can help you understand your own personal hormone
        levels. It shows you how they naturally fluctuate over your cycle and
        life stages and lets you keep track of any imbalances that makes you
        feel less than your best.As of now our tests are yet to be available but
        come back here in January to get access to the test
      </Typography>
      <Typography component='p'>
        As of now our tests are yet to be available but come back here in
        January to get access to the test!
      </Typography>
    </>
  );
}

function PCOS() {
  return (
    <>
      <Typography component='p' align='center'>
        Your quiz results have indicated that you may suffer from polycystic
        ovary syndrome (PCOS). However, this is only because of the answers you
        gave. You can read more about PCOS&nbsp;
        <a href='https://www.selfishdarling.com'>here.</a>
      </Typography>
      <Typography component='p' align='center'>
        PCOS is the result of hormone imbalance, so in order to give you a more
        conclusive picture of your hormonal health, we recommend you take the
        Female Hormonal Wellness test. This test is specifically developed to
        check the hormones involved in causing PCOS along with other hormonal
        imbalances, so it is a great starting point to find out more.
      </Typography>
      <Typography component='p' align='center'>
        As of now our tests are yet to be available but come back here in
        January to get access to the test!
      </Typography>
    </>
  );
}
function Endometriosis() {
  return (
    <>
      <Typography component='p'>
        Your quiz result has indicated that you may suffer from endometriosis.
        However, this is only because of the answers you gave. Endometriosis can
        unfortunately not be confirmed through a blood test and so we recommend
        you book an appointment with one of our endometriosis specialists&nbsp;
        <a href='https://www.selfishdarling.com'>here</a>
        &nbsp;or if you want more information you can find it&nbsp;
        <a href='https://www.selfishdarling.com'>here</a>.
      </Typography>
    </>
  );
}
function Thyroid() {
  return (
    <>
      <Typography component='p'>
        Your quiz results have indicated that you may suffer from thyroid issues
        but this is only because of the answers you gave. Thyroid problems arise
        when the thyroid gland produces either too much (overactive thyroid) of
        the thyroid hormones or not enough of them (underactive thyroid) find
        out more <a href='https://www.selfishdarling.com'>here</a>. To confirm
        whether you are suffering from one or the other we recommended you do
        our thyroid blood test.
      </Typography>
      <Typography component='p'>
        Thyroid problems are very common and usually affect other things such as
        energy related vitamins which is why we offer two types of thyroid
        tests.
      </Typography>
      <Typography component='p'>
        Thyroid Basic is an advanced thyroid blood test that checks key thyroid
        hormones TSH, FT4 and FT3 together with thyroid antibodies to establish
        if your thyroid is functioning correctly. This test alone will indicate
        whether or not you may be suffering from thyroid problems.
      </Typography>
      <Typography component='p'>
        Thyroid Plus includes the same as the Thyroid Basic, test but also
        checks vitamin D, active B-12 and ferritin to get an even better
        overview of how your thyroid problems may affect your overall wellbeing.
      </Typography>
      <Typography component='p'>
        As of now our tests are yet to be available but come back here in
        January to get access to the test!
      </Typography>
    </>
  );
}
