import { Fade, FormControl, FormLabel } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import * as Yup from 'yup';
import CustomFormButton from '../CustomFormButton';
import { FormFields } from '../FormContainer';
import SingleChoice from '../SingleChoice';
import Step2_2 from './Step2_2';
import Step2_3 from './Step2_3';

export const validationSchemaStep2_1 = Yup.object().shape({
  periodType1: Yup.mixed().when('periods', {
    is: 'yes',
    then: Yup.string().required(),
  }),
  periodType2: Yup.mixed().when('periods', {
    is: 'yes',
    then: Yup.string().required(),
  }),
  periodType3: Yup.mixed().when('periods', {
    is: 'yes',
    then: Yup.string().required(),
  }),
});

const periodTpe1Choices = [
  { value: 'regular', label: 'Regular' },
  { value: 'irregular', label: 'Irregular' },
];

const periodTpe2Choices = [
  { value: 'heavy', label: 'Heavy' },
  { value: 'normal', label: 'Normal' },
];

const periodTpe3Choices = [
  { value: 'painful', label: 'Painful' },
  { value: 'less_pain', label: 'Little to less pain' },
];

export default function Step2_1() {
  const {
    values,
    errors,
    submitForm,
    isValid,
    setFieldValue,
  } = useFormikContext<FormFields>();

  function handleClick(key: string, value: string) {
    setFieldValue(key, value, true);
  }

  return (
    <>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <FormControl
            component='div'
            error={
              !!errors.periodType1 ||
              !!errors.periodType2 ||
              !!errors.periodType3
            }
          >
            <SingleChoice
              title='Are your periods...'
              formKeyValue='periodType1'
              choices={periodTpe1Choices}
              onChoiceSelected={handleClick}
            />
            <div style={{ marginTop: 16 }} />
            {values.periodType1 && (
              <SingleChoice
                choices={periodTpe2Choices}
                formKeyValue='periodType2'
                onChoiceSelected={handleClick}
              />
            )}
            <div style={{ marginTop: 16 }} />
            {values.periodType2 && (
              <SingleChoice
                choices={periodTpe3Choices}
                formKeyValue='periodType3'
                onChoiceSelected={handleClick}
              />
            )}
          </FormControl>

          {values.periodType3 && isValid && (
            <CustomFormButton style={{ marginTop: 30 }} onClick={submitForm}>
              Next
            </CustomFormButton>
          )}
        </div>
    </>
  );
}
