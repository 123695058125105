import { Button, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';

import { ArrowRightAltRounded } from '@material-ui/icons';
import CustomFormButton from '../CustomFormButton';

export default function Step0() {
  const { submitForm } = useFormikContext();

  return (
    <>
      <Typography
        component='h4'
        variant='h4'
        color='primary'
        style={{ marginBottom: 43 }}
        align='center'
      >
        Hi Darling,
      </Typography>
      <Typography
        color='primary'
        component='h6'
        variant='h6'
        style={{ marginBottom: 98, maxWidth: 673 }}
        align='center'
      >
        So that we can help you in the best way possible we need to ask some
        personal questions about your overall wellbeing and female bits.
        <br></br>
        <br></br>
        But let's start off with the basics.
      </Typography>
      <section
        style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
      >
        <CustomFormButton
          onClick={submitForm}
          style={{ width: 200 }}
          endIcon={<ArrowRightAltRounded fontSize='large' />}
        >
          Let's start
        </CustomFormButton>
      </section>
    </>
  );
}
