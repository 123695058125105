import React from 'react';
import { FormControl } from '@material-ui/core';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';

import { FormFields } from '../FormContainer';
import SingleChoice from '../SingleChoice';


export const validationSchemaStep2 = Yup.object().shape({
  periods: Yup.string().required(),
});

const choices = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
];

export default function Step2() {
  const { errors, setFieldValue, submitForm } = useFormikContext<FormFields>();

  async function handleClick(key: string, value: string) {
    await setFieldValue(key, value, true);
    submitForm();
  }

  return (
    <>
      <FormControl component='div' error={!!errors.periods}>
        <SingleChoice
          title='Do you have periods'
          formKeyValue='periods'
          choices={choices}
          onChoiceSelected={handleClick}
        />
      </FormControl>
    </>
  );
}
