import React from 'react';

import styles from './styles'

const FormWrapper: React.FC = ({ children }) => {
  const classes = styles()
  return <div className={classes.formWrapper}>{children}</div>;
};

export default FormWrapper;
