import { Fade } from '@material-ui/core';
import { FormikConfig, FormikValues } from 'formik';
import React from 'react';

export interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {}

export function FormikStepWrapper({ children }: FormikStepProps) {
  return (
    <Fade in timeout={400}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center',
        }}
      >
        {children}
      </div>
    </Fade>
  );
}
