import { Button, IconButton, Typography } from '@material-ui/core';
import React from 'react';
import { ChevronLeftRounded, CloseRounded } from '@material-ui/icons';

import styles from './styles';
interface HeaderProps {
  onClose(): void;
  onBack?(): void;
}
export default function FormHeader({ onBack, onClose }: HeaderProps) {
  const classes = styles();

  return (
    <header className={classes.container}>
      {!!onBack ? (
        <Button
          id='backButton'
          variant='text'
          color='primary'
          startIcon={<ChevronLeftRounded fontSize='large' />}
          onClick={onBack}
        >
          Back
        </Button>
      ) : (
        <div />
      )}
      <Typography variant='h5' component='strong' color='primary'>
        Selfish Darling
      </Typography>
      <IconButton id='closeButton' onClick={onClose}>
        <CloseRounded color='primary' />
      </IconButton>
    </header>
  );
}
