import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import React from 'react';

import { useFormikContext } from 'formik';
import * as Yup from 'yup';

import { FormFields } from '../FormContainer';
import SingleChoice from '../SingleChoice';

export const validationSchemaStep4 = Yup.object().shape({
  weight_change: Yup.string().required(),
});

const weightChangeChoices = [
  { value: 'gained', label: 'Gained some' },
  { value: 'lost', label: 'Lost some' },
  { value: 'same', label: 'Same as always' },
];

export default function Step4() {
  const { errors } = useFormikContext<FormFields>();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FormControl component='fieldset' error={!!errors.weight_change}>
        <SingleChoice
          title='What’s your weight like?'
          formKeyValue='weight_change'
          choices={weightChangeChoices}
          column
        />
      </FormControl>
    </div>
  );
}
