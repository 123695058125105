import { Fade, FormControl } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { FormFields } from '../FormContainer';
import SingleChoice from '../SingleChoice';

export const validationSchemaStep2_3 = Yup.object().shape({
  no_periods: Yup.mixed().when('periods', {
    is: 'no',
    then: Yup.string().required(),
  }),
});

const noPeriodsChoices = [
  { value: 'naturally', label: 'It stopped naturally' },
  { value: 'contraception', label: "I'm using hormonal contraception" },
  { value: 'other', label: 'Other' },
];

export default function Step2_3() {
  const { errors, touched } = useFormikContext<FormFields>();

  return (
    <>
      <FormControl
        component='div'
        error={!touched.no_periods && !!errors.no_periods}
      >
        <SingleChoice
          title='Why do you think your periods stopped?'
          formKeyValue='no_periods'
          choices={noPeriodsChoices}
          column
        />
      </FormControl>
    </>
  );
}
