import { makeStyles, Theme, createStyles } from '@material-ui/core';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '95%',
      maxWidth: 600,
      padding: '50px 20px',
      color: theme.palette.primary.main,

      '& h3': {
        marginBottom: 30,
      },

      '& p + p': {
        marginTop: 20,
      },

      '& a': {
        color: 'initial',
        textDecoration: 'none',
        fontWeight: 'bold',
        transition: 'all ease-in-out 0.2s',
      },

      '& a:hover': {
        color: theme.palette.primary.light,
      },
    },
  })
);

export default styles;
