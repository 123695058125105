import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import React from 'react';

import { useFormikContext } from 'formik';
import * as Yup from 'yup';

import { FormFields } from '../FormContainer';
import SingleChoice from '../SingleChoice';

export const validationSchemaStep8 = Yup.object().shape({
  energy: Yup.string().required(),
});

const energyChoices = [
  { value: 'low', label: 'Low' },
  { value: 'normal', label: 'Normal' },
  { value: 'high', label: 'High' },
];

export default function Step8() {
  const { errors } = useFormikContext<FormFields>();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FormControl component='fieldset' error={!!errors.energy}>
        <SingleChoice
          formKeyValue='energy'
          choices={energyChoices}
          title="How's your energy levels?"
          column
        />
      </FormControl>
    </div>
  );
}
