import { FormControl } from '@material-ui/core';
import React from 'react';

import { useFormikContext } from 'formik';
import * as Yup from 'yup';

import { FormFields } from '../FormContainer';
import CustomFormButton from '../CustomFormButton';
import SingleChoice from '../SingleChoice';

export const validationSchemaStep10 = Yup.object().shape({
  pregnant: Yup.string().required(),
  pregnant_time: Yup.mixed().when('pregnant', {
    is: 'yes',
    then: Yup.string().required(),
  }),
});

const pregnantTimeChoices = [
  { value: 'started', label: 'Just started' },
  { value: 'six_months', label: 'Over 6 months' },
];
export default function Step10() {
  const {
    values,
    setFieldValue,
    errors,
    submitForm,
    isValid,
  } = useFormikContext<FormFields>();

  async function handleClick(key: string, value: string) {
    await setFieldValue(key, value, true);

    if (value === 'no') submitForm();
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FormControl component='fieldset' error={!!errors.pregnant}>
        <SingleChoice
          formKeyValue='pregnant'
          title='Are you trying to get pregnant?'
          onChoiceSelected={handleClick}
        />
      </FormControl>

      {values.pregnant === 'yes' && (
        <FormControl component='fieldset' error={!!errors.pregnant_time}>
          <SingleChoice
            title='How long have you been trying?'
            formKeyValue='pregnant_time'
            choices={pregnantTimeChoices}
            onChoiceSelected={handleClick}
          />
        </FormControl>
      )}
      {values.pregnant_time && isValid &&  (
        <CustomFormButton style={{ marginTop: 30 }} onClick={submitForm}>
          Next
        </CustomFormButton>
      )}
    </div>
  );
}
