import { FormLabel, TextField, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import { FormFields } from '../FormContainer';
import * as Yup from 'yup';
import CustomFormButton from '../CustomFormButton';

export const validationSchemaStep17 = Yup.object().shape({
  email: Yup.string()
    .email('Please provide a valid email')
    .required('This is a required field'),
});

export default function Step17() {
  const { values, handleChange, errors, submitForm } = useFormikContext<
    FormFields
  >();

  return (
    <>
      <Typography
        component='h4'
        variant='h4'
        color='primary'
        style={{ marginBottom: 16 }}
        align='center'
      >
        Finally there darling
      </Typography>
      <Typography
        component='h6'
        variant='h6'
        style={{ marginBottom: 8, color: '#737373' }}
        align='center'
      >
        Please enter your email to get access to your suitable test.
      </Typography>

      <FormLabel component='legend' style={{ margin: '20px 0' }}>
        Your best email
      </FormLabel>
      <TextField
        variant='outlined'
        type='email'
        name='email'
        value={values.email}
        placeholder='Email'
        onChange={handleChange}
        style={{ width: '100%', maxWidth: 340 }}
        error={!!errors.email}
        helperText={errors.email}
      />
      <CustomFormButton style={{ marginTop: 30 }} onClick={submitForm}>
        See results
      </CustomFormButton>
    </>
  );
}
