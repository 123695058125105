import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import React from 'react';

import { useFormikContext } from 'formik';
import * as Yup from 'yup';

import { FormFields } from '../FormContainer';
import SingleChoice from '../SingleChoice';

export const validationSchemaStep6 = Yup.object().shape({
  hair_loss: Yup.string().required(),
});

export default function Step6() {
  const { errors } = useFormikContext<FormFields>();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FormControl component='fieldset' error={!!errors.hair_loss}>
        <SingleChoice
          title='Have you noticed hair loss?'
          formKeyValue='hair_loss'
        />
      </FormControl>
    </div>
  );
}
