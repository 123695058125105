import React, { useState } from 'react';
import api from '../services/api';
import mailchimpService from '../services/mailchimpService';
import { FormikStepWrapper } from './FormikStepProps';
import { FormikWrapper } from './FormikWrapper';
import Result from './ResultPage';
import Step0 from './Steps/Step0';
import Step1, { validationSchemaStep1 } from './Steps/Step1';
import Step10, { validationSchemaStep10 } from './Steps/Step10';
import Step11, { validationSchemaStep11 } from './Steps/Step11';
import Step12, { validationSchemaStep12 } from './Steps/Step12';
import Step13, { validationSchemaStep13 } from './Steps/Step13';
import Step14, { validationSchemaStep14 } from './Steps/Step14';
import Step15, { validationSchemaStep15 } from './Steps/Step15';
import Step16 from './Steps/Step16';
import Step17, { validationSchemaStep17 } from './Steps/Step17';
import Step1_1, { validationSchemaStep1_1 } from './Steps/Step1_1';
import Step2, { validationSchemaStep2 } from './Steps/Step2';
import Step2_1, { validationSchemaStep2_1 } from './Steps/Step2_1';
import Step2_2, { validationSchemaStep2_2 } from './Steps/Step2_2';
import Step2_3, { validationSchemaStep2_3 } from './Steps/Step2_3';
import Step3, { validationSchemaStep3 } from './Steps/Step3';
import Step4, { validationSchemaStep4 } from './Steps/Step4';
import Step5, { validationSchemaStep5 } from './Steps/Step5';
import Step6, { validationSchemaStep6 } from './Steps/Step6';
import Step7, { validationSchemaStep7 } from './Steps/Step7';
import Step8, { validationSchemaStep8 } from './Steps/Step8';
import Step9, { validationSchemaStep9 } from './Steps/Step9';
import processResult from './utils/processResult';
import processScore from './utils/processScore';

export interface FormFields {
  female?: 'yes' | 'no' | '';
  age?: string;
  periods: 'yes' | 'no' | '';
  no_periods?: 'naturally' | 'contraception' | 'other' | '';
  periodType1?: 'regular' | 'irregular' | '';
  periodType2?: 'heavy' | 'normal' | '';
  periodType3?: 'painful' | 'less_pain' | '';
  peeing1?: 'painful' | 'normal' | '';
  peeing2?: 'blood' | 'normal' | '';
  hormonalMedicine: 'yes' | 'no' | '';
  weight_change: 'gained' | 'same' | 'lost' | '';
  skin: string[];
  hair_loss: 'yes' | 'no' | '';
  tummy: string;
  energy: 'low' | 'high' | 'normal' | '';
  sleep_quality: 'poor' | 'average' | 'great' | '';
  pregnant: 'yes' | 'no' | '';
  pregnant_time?: 'started' | 'six_months' | '';
  temperature: 'sensitive_cold' | 'hot_flashes' | 'normal' | '';
  pelvic_lower_back: 'yes' | 'no' | '';
  pain_after_sex: 'yes' | 'no' | '';
  mood_changes: string[];
  memory_loss: 'yes' | 'no' | '';
  life_quality: number;
  email: string;
}

const initialValues = {
  female: '',
  age: '',
  periods: '',
  skin: [],
  no_periods: '',
  hormonalMedicine: '',
  peeing1: '',
  peeing2: '',
  periodType1: '',
  periodType2: '',
  periodType3: '',
  weight_change: '',
  hair_loss: '',
  pregnant: '',
  pregnant_time: '',
  tummy: '',
  energy: '',
  sleep_quality: '',
  temperature: '',
  pain_after_sex: '',
  pelvic_lower_back: '',
  mood_changes: [],
  memory_loss: '',
  life_quality: 5,
  email: '',
};

// const steps = [];

export default function FormContainer() {
  const [showResult, setShowResult] = useState(false);
  const [formValues, setFormValues] = useState({});

  // const [children, setChildren] = useState(steps);

  const [showPeriodQuestions, setShowPeriodQuestions] = useState(false);

  async function handleSubmit(values: FormFields) {
    setShowResult(true);
    setFormValues(values);

    const score = processScore(values);
    const result = processResult(score, values);

    const form = { ...values, result };
    await api.post(
      `questionnaire/${values.email}`,
      form
    );

    await mailchimpService.addSubscriber(values.email);
  }

  if (showResult) {
    return <Result values={formValues as FormFields} />;
  }

  function handleOnPeriod(show: boolean) {
    if (show !== showPeriodQuestions) setShowPeriodQuestions(show);
  }

  return (
    <>
      <FormikWrapper
        initialValues={initialValues}
        onSubmit={values => handleSubmit(values as FormFields)}
        onPeriod={handleOnPeriod}
      >
        <Step0 />
        <FormikStepWrapper validationSchema={validationSchemaStep1}>
          <Step1 />
        </FormikStepWrapper>
        <FormikStepWrapper validationSchema={validationSchemaStep1_1}>
          <Step1_1 />
        </FormikStepWrapper>
        <FormikStepWrapper validationSchema={validationSchemaStep2}>
          <Step2 />
        </FormikStepWrapper>
        {showPeriodQuestions && (
          <FormikStepWrapper validationSchema={validationSchemaStep2_1}>
            <Step2_1 />
          </FormikStepWrapper>
        )}
        {showPeriodQuestions && (
          <FormikStepWrapper validationSchema={validationSchemaStep2_2}>
            <Step2_2 />
          </FormikStepWrapper>
        )}
        {!showPeriodQuestions && (
          <FormikStepWrapper validationSchema={validationSchemaStep2_3}>
            <Step2_3 />
          </FormikStepWrapper>
        )}

        <FormikStepWrapper validationSchema={validationSchemaStep3}>
          <Step3 />
        </FormikStepWrapper>
        <FormikStepWrapper validationSchema={validationSchemaStep4}>
          <Step4 />
        </FormikStepWrapper>
        <FormikStepWrapper validationSchema={validationSchemaStep5}>
          <Step5 />
        </FormikStepWrapper>
        <FormikStepWrapper validationSchema={validationSchemaStep6}>
          <Step6 />
        </FormikStepWrapper>
        <FormikStepWrapper validationSchema={validationSchemaStep7}>
          <Step7 />
        </FormikStepWrapper>
        <FormikStepWrapper validationSchema={validationSchemaStep8}>
          <Step8 />
        </FormikStepWrapper>
        <FormikStepWrapper validationSchema={validationSchemaStep9}>
          <Step9 />
        </FormikStepWrapper>
        <FormikStepWrapper validationSchema={validationSchemaStep10}>
          <Step10 />
        </FormikStepWrapper>
        <FormikStepWrapper validationSchema={validationSchemaStep11}>
          <Step11 />
        </FormikStepWrapper>
        <FormikStepWrapper validationSchema={validationSchemaStep12}>
          <Step12 />
        </FormikStepWrapper>
        <FormikStepWrapper validationSchema={validationSchemaStep13}>
          <Step13 />
        </FormikStepWrapper>
        <FormikStepWrapper validationSchema={validationSchemaStep14}>
          <Step14 />
        </FormikStepWrapper>
        <FormikStepWrapper validationSchema={validationSchemaStep15}>
          <Step15 />
        </FormikStepWrapper>
        <FormikStepWrapper>
          <Step16 />
        </FormikStepWrapper>
        <FormikStepWrapper validationSchema={validationSchemaStep17}>
          <Step17 />
        </FormikStepWrapper>
      </FormikWrapper>
    </>
  );
}
