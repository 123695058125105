import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import React from 'react';

import { useFormikContext } from 'formik';
import * as Yup from 'yup';

import { FormFields } from '../FormContainer';
import SingleChoice from '../SingleChoice';

export const validationSchemaStep9 = Yup.object().shape({
  sleep_quality: Yup.string().required(),
});

const sleepQualityChoices = [
  { value: 'poor', label: 'Poor' },
  { value: 'average', label: 'Average' },
  { value: 'great', label: 'Great' },
];

export default function Step9() {
  const { errors } = useFormikContext<FormFields>();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FormControl component='fieldset' error={!!errors.sleep_quality}>
        <SingleChoice
          formKeyValue='sleep_quality'
          title='How’s your sleep quality?'
          choices={sleepQualityChoices}
          column
        />
      </FormControl>
    </div>
  );
}
