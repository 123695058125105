import { createStyles, FormLabel, makeStyles, Theme } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { FormikValues, useFormikContext } from 'formik';
import React from 'react';
import CustomFormButton from './CustomFormButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    choicesContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        minWidth: 340,
        fontSize: '14px !important',
      },

      '& button': {
        width: '90%',
        marginBottom: 24,

        [theme.breakpoints.down('sm')]: {
          fontSize: '12px !important',
        },
      },
    },
    label: { marginBottom: 96 },
  })
);

interface Choice {
  value: string;
  label: string;
}

interface SingleChoiceProps {
  title?: string;
  formKeyValue: string;
  choices: Choice[];
  containerStyle?: React.CSSProperties;
  onChoiceSelected?(key: string, value: string): void;
}

export default function MultiChoice({
  choices,
  containerStyle,
  formKeyValue: key,
  onChoiceSelected,
  title,
}: SingleChoiceProps) {
  const classes = useStyles();

  const { values, setFieldValue, submitForm } = useFormikContext<
    FormikValues
  >();

  async function onSelected(value: string) {
    const valuesCopy = values[key] as string[];
    if (onChoiceSelected) {
      return onChoiceSelected(key, value);
    }

    if (valuesCopy.includes(value)) {
      await setFieldValue(
        key,
        valuesCopy.filter(item => item !== value),
        true
      );
      return;
    }

    await setFieldValue(key, [...values[key], value], true);
  }

  return (
    <>
      {title && (
        <FormLabel
          className={classes.label}
          style={containerStyle}
          component='legend'
        >
          {title}
        </FormLabel>
      )}

      <section
        className={classes.choicesContainer}
        style={{
          ...containerStyle,
        }}
      >
        {choices.map(({ value, label }) => (
          <CustomFormButton
            key={value}
            onClick={() => onSelected(value)}
            style={{
              border: `${
                values[key].includes(value)
                  ? '3px solid #001058'
                  : '3px solid transparent'
              } `,
            }}
            startIcon={
              values[key].includes(value) && <Check fontSize='small' />
            }
          >
            {label}
          </CustomFormButton>
        ))}
      </section>
    </>
  );
}
