import axios from 'axios';

const mailchimpService = axios.create();
const LIST_ID = 'ffc67589ee';

const addSubscriber = async (email: string) =>
  await mailchimpService.get(
    `https://selfishdarling.us12.list-manage.com/subscribe/post-json?u=76300afd05b1c66d2256b505a&amp;id=${LIST_ID}&EMAIL=${email}&c=__jp0`
  );

export default {
  addSubscriber,
};
