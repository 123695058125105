import { createStyles, FormLabel, makeStyles, Theme } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { FormikValues, useFormikContext } from 'formik';
import React from 'react';
import CustomFormButton from './CustomFormButton';

interface Choice {
  value: string;
  label: string;
}

interface SingleChoiceProps {
  title?: string;
  formKeyValue: string;
  choices?: Choice[];
  selected?: string;
  containerStyle?: React.CSSProperties;
  onChoiceSelected?(key: string, value: string): void;
  column?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    choicesContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        fontSize: '14px !important',
      },

      '& button': {
        width: 339,
        marginBottom: 24,
        margin: '0 12px 24px 12px',

        [theme.breakpoints.down('sm')]: {
          width: 150,
          fontSize: '12px !important',
          margin: '0 8px 24px 8px',
        },
      },
    },
    label: { marginBottom: 96 },
  })
);

const defaultChoices = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
];

export default function SingleChoice({
  choices = defaultChoices,
  containerStyle,
  formKeyValue,
  column = false,
  selected,
  onChoiceSelected,
  title,
}: SingleChoiceProps) {
  const classes = useStyles();

  const { values, setFieldValue, submitForm } = useFormikContext<
    FormikValues
  >();

  async function onSelected(value: string) {
    if (onChoiceSelected) {
      return onChoiceSelected(formKeyValue, value);
    }

    await setFieldValue(formKeyValue, value, true);
    submitForm();
  }

  const direction = column ? 'column' : 'row';

  return (
    <>
      {title && (
        <FormLabel
          className={classes.label}
          style={containerStyle}
          component='legend'
        >
          {title}
        </FormLabel>
      )}

      <section
        className={classes.choicesContainer}
        style={{
          flexDirection: direction,
          ...containerStyle,
        }}
      >
        {choices.map(({ value, label }) => (
          <CustomFormButton
            key={value}
            onClick={() => onSelected(value)}
            style={{
              border: `${
                values[formKeyValue] !== value
                  ? '3px solid transparent'
                  : '3px solid #001058'
              } `,
              minWidth: `${column && '100%'}`,
            }}
            startIcon={
              values[formKeyValue] === value && <Check fontSize='small' />
            }
          >
            {label}
          </CustomFormButton>
        ))}
      </section>
    </>
  );
}
