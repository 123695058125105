import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import React from 'react';
import FormContainer from './components/FormContainer';
import FormWrapper from './components/FormWrapper';
import theme from './theme';

import './app.css';

function App() {
  return (
    <>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <FormWrapper>
          <FormContainer />
        </FormWrapper>
      </MuiThemeProvider>
    </>
  );
}

export default App;
