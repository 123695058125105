import { FormControl } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import CustomFormButton from '../CustomFormButton';
import { FormFields } from '../FormContainer';
import MultiChoice from '../MultiChoice';

export const validationSchemaStep5 = Yup.object().shape({
  skin: Yup.array()
    .required()
    .test(
      'valid',
      "When you select no changes, you can't select other values",
      function () {
        if (
          this.parent.skin.includes('no_changes') &&
          this.parent.skin.length > 1
        )
          return false;

        return true;
      }
    ),
});

const skinSymptomList = [
  { value: 'acne', label: 'Acne' },
  { value: 'melasma', label: 'Melasma' },
  { value: 'dryness', label: 'Dryness' },
  { value: 'oily', label: 'Oily' },
  { value: 'itchy', label: 'Itchy' },
  { value: 'excessive_hair', label: 'Excessive bodily hair growth' },
  { value: 'no_changes', label: 'No changes' },
];
export default function Step5() {
  const { errors, submitForm } = useFormikContext<FormFields>();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FormControl component='fieldset' error={!!errors.skin}>
        <MultiChoice
          formKeyValue='skin'
          title=' Are you experiencing any changes in your skin?'
          choices={skinSymptomList}
        />
      </FormControl>
      <CustomFormButton style={{ marginTop: 30 }} onClick={submitForm}>
        Next
      </CustomFormButton>
    </div>
  );
}
