import React from 'react';
import { FormControl, FormLabel } from '@material-ui/core';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';
import CustomFormButton from '../CustomFormButton';
import { FormFields } from '../FormContainer';
import MultiChoice from '../MultiChoice';

export const validationSchemaStep14 = Yup.object().shape({
  mood_changes: Yup.array()
    .required()
    .test(
      'valid',
      "When you select no changes, you can't select other values",
      function () {
        if (
          this.parent.mood_changes.includes('no_changes') &&
          this.parent.mood_changes.length > 1
        )
          return false;

        return true;
      }
    ),
});

const moodSymptomsChoices = [
  { value: 'depression', label: 'Depression' },
  { value: 'panic', label: 'Panic' },
  { value: 'anxiety', label: 'Anxiety' },
  { value: 'irritability', label: 'Irritability' },
  { value: 'suicidal', label: 'Suicidal' },
  { value: 'super_positive', label: 'Super positive' },
  { value: 'no_changes', label: 'No mood changes' },
];

export default function Step14() {
  const {
    values,
    getFieldProps,
    errors,
    submitForm,
  } = useFormikContext<FormFields>();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FormControl component='fieldset' error={!!errors.mood_changes}>
        <FormLabel component='legend'></FormLabel>

        <MultiChoice
          formKeyValue='mood_changes'
          title='Are you experiencing any mood changes?'
          choices={moodSymptomsChoices}
        />
        <CustomFormButton style={{ marginTop: 30 }} onClick={submitForm}>
          Next
        </CustomFormButton>
      </FormControl>
    </div>
  );
}
