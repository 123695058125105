import { Fade, FormControl, FormLabel } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { useLayoutEffect } from 'react';
import * as Yup from 'yup';
import CustomFormButton from '../CustomFormButton';
import { FormFields } from '../FormContainer';
import SingleChoice from '../SingleChoice';

export const validationSchemaStep2_2 = Yup.object().shape({
  peeing1: Yup.mixed().when('periods', {
    is: 'yes',
    then: Yup.string().required(),
  }),
  peeing2: Yup.mixed().when('periods', {
    is: 'yes',
    then: Yup.string().required(),
  }),
});

const peeing1Choices = [
  { value: 'painful', label: 'Painful' },
  { value: 'normal', label: 'Normal' },
];

const peeing2Choices = [
  { value: 'normal', label: 'Normal' },
  { value: 'blood', label: 'Blood in my pee' },
];

export default function Step2_2() {
  const { values, submitForm, isValid, setFieldValue } = useFormikContext<
    FormFields
  >();

  function handleClick(key: string, value: string) {
    setFieldValue(key, value, true);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <SingleChoice
        title="What's peeing in your period like?"
        formKeyValue='peeing1'
        choices={peeing1Choices}
        onChoiceSelected={handleClick}
      />

      <div style={{ marginTop: 16 }} />
      {values.peeing1 && (
        <SingleChoice
          formKeyValue='peeing2'
          choices={peeing2Choices}
          onChoiceSelected={handleClick}
        />
      )}
      {values.peeing2 && isValid && (
        <CustomFormButton
          style={{ marginTop: 30, width: '100%' }}
          onClick={submitForm}
        >
          Next
        </CustomFormButton>
      )}
    </div>
  );
}
