import { FormFields } from '../FormContainer';

interface Score {
  pcos: number;
  endometriosis: number;
  noSymptoms: number;
  thyroid: number;
}

export default function processResult(score: Score, values: FormFields) {
  if (
    score.pcos >= 3 &&
    (values.pregnant_time === 'six_months' || values.no_periods === 'naturally')
  ) {
    return 'pcos';
  }

  let result = { value: 0, index: 0 };

  Object.values(score).forEach((value, index) => {
    if (value > result.value) {
      result = { value, index };
    }
  });

  return Object.keys(score)[result.index];
}

