import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import React from 'react';

import { useFormikContext } from 'formik';
import * as Yup from 'yup';

import { FormFields } from '../FormContainer';
import SingleChoice from '../SingleChoice';

interface Props {
  handleNextStep(): void;
  handlePreviousStep(): void;
  handleChange(input: string, value: any): void;
  values: any;
}

export const validationSchemaStep12 = Yup.object().shape({
  pelvic_lower_back: Yup.string().required(),
});

export default function Step12() {
  const { errors } = useFormikContext<FormFields>();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FormControl component='fieldset' error={!!errors.pelvic_lower_back}>
        <SingleChoice
          formKeyValue='pelvic_lower_back'
          title='Are you experiencing any pelvic or lower back pains?'
        />
      </FormControl>
    </div>
  );
}
