import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import React from 'react';

import { useFormikContext } from 'formik';
import * as Yup from 'yup';

import { FormFields } from '../FormContainer';
import SingleChoice from '../SingleChoice';

export const validationSchemaStep11 = Yup.object().shape({
  temperature: Yup.string().required(),
});

const temperatureChoices = [
  { value: 'sensitive_cold', label: "I'm sensitive to cold" },
  { value: 'hot_flashes', label: "I'm experiencing hot flashes" },
  { value: 'normal', label: "I'm normal" },
];

export default function Step11() {
  const { errors } = useFormikContext<FormFields>();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FormControl component='fieldset' error={!!errors.temperature}>
        <FormLabel component='legend'></FormLabel>
        <SingleChoice
          formKeyValue='temperature'
          title='What’s your body temperature like?'
          choices={temperatureChoices}
          column
        />
      </FormControl>
    </div>
  );
}
