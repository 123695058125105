import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';

interface NotValidProps {
  onReturn?(): void;
}

export default function NotValid({ onReturn }: NotValidProps) {
  const { setFieldValue } = useFormikContext();
  return (
    <>
      <Typography component='h4' align='center'>
        Sorry but this quiz is exclusively designed for women..
      </Typography>
      <Button color='secondary' onClick={onReturn}>
        Go back
      </Button>
      <Button
        color='secondary'
        onClick={() =>
          (window.location.href = 'https://www.selfishdarling.com/')
        }
      >
        Go to homepage
      </Button>
    </>
  );
}
