import { FormFields } from '../FormContainer';

export default function processScore(values: FormFields) {
  let temporaryScore = {
    noSymptoms: 0,
    thyroid: 0,
    endometriosis: 0,
    pcos: 0,
  };

  Object.keys(values).forEach(key => {
    if (values.periods === 'yes') {
      if (key === 'periodType1') {
        if (values[key] === 'irregular') {
          temporaryScore.thyroid += 1;
          temporaryScore.pcos += 1;
        } else {
          temporaryScore.noSymptoms += 1;
        }
      }

      if (key === 'periodType2') {
        if (values[key] === 'heavy') {
          temporaryScore.thyroid += 1;
          temporaryScore.endometriosis += 1;
        } else {
          temporaryScore.noSymptoms += 1;
        }
      }

      if (key === 'periodType3') {
        if (values[key] === 'painful') {
          temporaryScore.endometriosis += 1;
        } else {
          temporaryScore.noSymptoms += 1;
        }
      }

      if (key === 'peeing1') {
        if (values[key] === 'painful') {
          temporaryScore.endometriosis += 1;
        } else {
          temporaryScore.noSymptoms += 1;
        }
      }
      if (key === 'peeing2') {
        if (values[key] === 'blood') {
          temporaryScore.endometriosis += 1;
        } else {
          temporaryScore.noSymptoms += 1;
        }
      }
    } else {
      if (key === 'no_periods') {
        if (values[key] === 'naturally') {
          temporaryScore.pcos += 1;
        } else {
          temporaryScore.noSymptoms += 1;
        }
      }
    }

    if (key === 'weight_change') {
      if (values[key] === 'gained') {
        temporaryScore.pcos += 1;
        temporaryScore.thyroid += 1;
      } else if (values[key] === 'lost') {
        temporaryScore.thyroid += 1;
      } else {
        temporaryScore.noSymptoms += 1;
      }
    }

    if (key === 'skin') {
      if (
        values[key].includes('excessive_hair') ||
        values[key].includes('acne') ||
        values[key].includes('oily')
      ) {
        temporaryScore.pcos += 1;
      }

      if (
        values[key].includes('itchy') ||
        values[key].includes('dryness') ||
        values[key].includes('melasma')
      ) {
        temporaryScore.thyroid += 1;
      }
    }

    if (key === 'hair_loss') {
      if (values[key] === 'yes') {
        temporaryScore.pcos += 1;
        temporaryScore.thyroid += 1;
      } else {
        temporaryScore.noSymptoms += 1;
      }
    }

    if (key === 'tummy') {
      if (
        values[key] === 'food_intolerance' ||
        values[key] === 'gas_bloating'
      ) {
        temporaryScore.thyroid += 1;
      }
      if (values[key] === 'diarrhoea' || values[key] === 'constipation') {
        temporaryScore.thyroid += 1;
        temporaryScore.endometriosis += 1;
      }

      if (values[key] === 'nausea') {
        temporaryScore.endometriosis += 1;
      }
    }

    if (key === 'energy') {
      if (values[key] === 'normal') {
        temporaryScore.noSymptoms += 1;
      } else {
        temporaryScore.thyroid += 1;
      }
    }

    if (key === 'sleep_quality') {
      if (values[key] === 'poor') {
        temporaryScore.thyroid += 1;
      } else {
        temporaryScore.noSymptoms += 1;
      }
    }

    if (key === 'pregnant_time') {
      if (values[key] === 'six_months') {
        temporaryScore.pcos += 1;
        temporaryScore.endometriosis += 1;
      } else {
        temporaryScore.noSymptoms += 1;
      }
    }

    if (key === 'temperature') {
      if (values[key] === 'normal') {
        temporaryScore.noSymptoms += 1;
      } else {
        temporaryScore.thyroid += 1;
      }
    }

    if (key === 'pelvic_lower_back') {
      if (values[key] === 'no') {
        temporaryScore.noSymptoms += 1;
      } else {
        temporaryScore.endometriosis += 1;
      }
    }

    if (key === 'pain_after_sex') {
      if (values[key] === 'no') {
        temporaryScore.noSymptoms += 1;
      } else {
        temporaryScore.endometriosis += 1;
      }
    }

    if (key === 'mood_changes') {
      if (
        values[key].includes('panic') ||
        values[key].includes('depression') ||
        values[key].includes('anxiety') ||
        values[key].includes('suicidal') ||
        values[key].includes('irritability')
      ) {
        temporaryScore.thyroid += 1;
      }
    }

    if (key === 'memory_loss') {
      if (values[key] === 'no') {
        temporaryScore.noSymptoms += 1;
      } else {
        temporaryScore.thyroid += 1;
      }
    }
  });
  return temporaryScore;
}
