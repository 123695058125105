import { Button, ButtonProps, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
interface CustomButtonProps extends ButtonProps {}

const buttonStyle = {
  height: 48,
  border: '3px solid #001058',
  background: '#F7DC8B',
  textTransform: 'initial',
  fontSize: 16,
  fontWeight: 'bold',
} as React.CSSProperties;

export default function CustomFormButton({
  style,
  children,
  ...rest
}: CustomButtonProps) {
  return (
    <Button
      style={{
        ...buttonStyle,
        ...style,
      }}
      {...rest}
    >
      {children}
    </Button>
  );
}
