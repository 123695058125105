import {
  FormLabel,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import { FormFields } from '../FormContainer';
import * as Yup from 'yup';
import { ArrowRightAltRounded } from '@material-ui/icons';

export const validationSchemaStep1_1 = Yup.object().shape({
  age: Yup.number().min(18).max(100).required().label('Age'),
});

export default function Step1_1() {
  const { values, handleChange, errors, submitForm } = useFormikContext<
    FormFields
  >();

  return (
    <>
      <FormLabel component='legend' style={{ marginBottom: 96 }}>
        What's your age?
      </FormLabel>
      <TextField
        autoFocus
        variant='outlined'
        placeholder='Age'
        onChange={handleChange('age')}
        value={values.age}
        style={{ width: '100%', maxWidth: 340, fontWeight: 'bold' }}
        error={!!errors.age}
        helperText={errors.age}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton aria-label='next' onClick={submitForm}>
                <ArrowRightAltRounded fontSize='large' color='primary' />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}
