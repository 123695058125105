import {
  createStyles,
  FormControl,
  FormLabel,
  makeStyles,
  Slider,
  Theme,
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import CustomFormButton from '../CustomFormButton';

import { FormFields } from '../FormContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
    },

    slider: {
      width: 250,
      margin: '50px 0',

      '& .MuiSlider-thumb': {
        width: 24,
        height: 24,
        marginTop: -11,
        background: theme.palette.secondary.main,
        border: `2px solid ${theme.palette.primary.main}`,
      },

      '& .MuiSlider-markLabel': {
        width: 50,
        whiteSpace: 'normal',
      },

      '& .MuiSlider-valueLabel': {
        left: '-30%',
      },

      [theme.breakpoints.up('sm')]: {
        width: '80%',

        '& .MuiSlider-track': {
          height: 6,
        },

        '& .MuiSlider-thumb': {
          width: 40,
          height: 40,
          marginTop: -20,
        },

        '& .MuiSlider-valueLabel': {
          left: 0,
        },
      },
    },
  })
);

export default function Step16() {
  const { values, setFieldValue, submitForm } = useFormikContext<FormFields>();

  const classes = useStyles();

  const marks = [
    { value: 0, label: 'Extremely unsatisfied' },
    { value: 10, label: 'Extremely satisfied' },
  ];

  function handleChange(
    event: React.ChangeEvent<{}>,
    value: number | number[]
  ) {
    setFieldValue('life_quality', value);
  }

  return (
    <>
      <FormLabel component='legend'>
        How satisfied are you with your overall health and quality of life?
      </FormLabel>
      <Slider
        id='life_quality'
        valueLabelDisplay='auto'
        className={classes.slider}
        min={0}
        max={10}
        marks={marks}
        value={values.life_quality}
        onChange={(event, value) => handleChange(event, value)}
      />
      <CustomFormButton
        style={{ width: 240, marginTop: 30 }}
        onClick={submitForm}
      >
        Next
      </CustomFormButton>
    </>
  );
}
