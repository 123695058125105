import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import React from 'react';

import { useFormikContext } from 'formik';
import * as Yup from 'yup';

import { FormFields } from '../FormContainer';
import SingleChoice from '../SingleChoice';

export const validationSchemaStep7 = Yup.object().shape({
  tummy: Yup.string().required(),
});

const tummyChoices = [
  { value: 'diarrhoea', label: 'Diarrhoea' },
  { value: 'food_intolerance', label: 'Food intolerance' },
  { value: 'gas_bloating', label: 'Gas & bloating' },
  { value: 'constipation', label: 'Constipation' },
  { value: 'nausea', label: 'Nausea' },
  { value: 'fine', label: 'Fine' },
];

export default function Step7() {
  const { errors } = useFormikContext<FormFields>();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FormControl component='fieldset' error={!!errors.tummy}>
        <SingleChoice
          formKeyValue='tummy'
          title="How's your tummy feeling?"
          choices={tummyChoices}
          column
        />
      </FormControl>
    </div>
  );
}
