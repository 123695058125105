import React, {
  Children,
  ReactElement,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import {
  Form,
  Formik,
  FormikConfig,
  FormikHelpers,
  FormikProps,
  FormikValues,
} from 'formik';

import { FormikStepProps } from './FormikStepProps';
import FormHeader from './Header';
import FormProgress from './FormProgress';

export interface FormikWrapperProps extends FormikConfig<FormikValues> {
  onPeriod(show: boolean): void;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: 860,
      margin: '0 auto',
      padding: '0 20px',

      [theme.breakpoints.down('sm')]: {
        width: 360,
      },
    },
  })
);

export function FormikWrapper({
  children,
  onPeriod,
  ...props
}: FormikWrapperProps) {
  const classes = useStyles();

  const childrenArray = Children.toArray(
    children
  ) as ReactElement<FormikStepProps>[];

  const [step, setStep] = useState(0);

  const formRef = useRef<FormikProps<FormikValues>>(null);

  const currentChild = childrenArray[step];

  const isLastStep = useMemo(() => childrenArray.length - 1 === step, [
    childrenArray.length,
    step,
  ]);

  function handleGoBack() {
    if (step > 0) {
      setStep(step - 1);
    }
  }

  async function handleSubmit(
    values: FormikValues,
    helpers: FormikHelpers<FormikValues>
  ) {
    if (isLastStep) {
      return await props.onSubmit(values, helpers);
    }
    setStep(step + 1);
  }

  if (formRef.current?.values.periods === 'yes') {
    onPeriod(true);
  } else {
    onPeriod(false);
  }

  return (
    <>
      <FormProgress
        totalQuestions={childrenArray.length}
        currentIndex={step <= 1 ? 0.2 : step}
      />
      <FormHeader
        onBack={handleGoBack}
        onClose={() => {
          window.location.href = 'https://www.selfishdarling.com/';
        }}
      />
      <Formik
        innerRef={formRef}
        {...props}
        validationSchema={currentChild?.props.validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form className={classes.form}>
            <>{currentChild}</>
          </Form>
        )}
      </Formik>
    </>
  );
}
