import { createMuiTheme } from '@material-ui/core';

export default createMuiTheme({
  typography: {
    fontFamily: ['DM Sans', 'sans-serif'].join(','),
    htmlFontSize: 16,
  },
  palette: {
    primary: {
      main: '#001058',
      light: '#9AC7FA',
      contrastText: '#ffffff',
      dark: '#144061',
    },
    secondary: {
      main:'#F7DC8B',
    },
  },
});
