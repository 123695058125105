import {
  createStyles,
  LinearProgress,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

interface FormProgressProps {
  totalQuestions: number;
  currentIndex: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    linearProgress: {
      height: 24,
      marginBottom:8,
      background: 'transparent',

      '& .MuiLinearProgress-barColorPrimary': {
        background: theme.palette.primary.light,
      },
    },
  })
);

export default function FormProgress({
  totalQuestions,
  currentIndex,
}: FormProgressProps) {
  const classes = useStyles();
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    setProgress((currentIndex / totalQuestions) * 100);
  }, [currentIndex, totalQuestions]);

  return (
    <div style={{ width: '100%' }}>
      <LinearProgress
        className={classes.linearProgress}
        aria-describedby='progress'
        variant='determinate'
        value={progress}
        style={{ height: 24 }}
        color='primary'
      />
    </div>
  );
}
