import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { FormFields } from '../FormContainer';
import SingleChoice from '../SingleChoice';

export const validationSchemaStep3 = Yup.object().shape({
  hormonalMedicine: Yup.string().required(),
});

export default function Step3() {
  const { errors } = useFormikContext<FormFields>();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FormControl component='fieldset' error={!!errors.hormonalMedicine}>
        <SingleChoice
          title='Are you on any hormonal medicine? (such as oral contraception, IUD,
          HRT)'
          formKeyValue='hormonalMedicine'
        />
      </FormControl>
    </div>
  );
}
