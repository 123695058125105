import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { FormFields } from '../FormContainer';
import * as Yup from 'yup';
import CustomFormButton from '../CustomFormButton';
import SingleChoice from '../SingleChoice';
import NotValid from './NotValid';

export const validationSchemaStep1 = Yup.object().shape({
  female: Yup.string().required(),
});

export default function Step1() {
  const { setFieldValue, handleSubmit } = useFormikContext<FormFields>();
  const [notValid, setNotValid] = useState(false);

  async function handleClick(field: string, value: string) {
    await setFieldValue(field, value, true);

    if (value === 'yes') {
      handleSubmit();
    } else {
      setNotValid(true);
    }
  }

  const choices = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ];

  async function handleReturn() {
    setFieldValue('female', '', true);
    setNotValid(false);
  }

  if (notValid) {
    return <NotValid onReturn={handleReturn} />;
  }

  return (
    <>
      <SingleChoice
        title='Are you female?'
        formKeyValue='female'
        choices={choices}
        onChoiceSelected={handleClick}
      />
    </>
  );
}
